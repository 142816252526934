<template>
  <div class="repairlist">
    <NavBar :list="[{ name: '报修列表' }]" name="报修管理"></NavBar>
    <div class="public-box">
      <el-form :inline="true">
        <el-form-item>
          <MyInput v-model="formInline.merchants_name" placeholder="输入报修人姓名">
            <template slot="pre">报修人姓名:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyInput v-model="formInline.merchants_mobile" placeholder="输入报修人电话">
            <template slot="pre">报修人电话:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <el-select v-model="formInline.repair_status" @change="search(1)" placeholder="状态">
            <el-option v-for="(item, index) of status" :key="index" :value="item.value" :label="item.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" left @click="search">
            搜索
            <template slot="preImage">
              <img src="../../unit/img/search.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <myButton @click="visible = true" :accessId="24251">新增报修
            <template slot="preImage">
              <img src="../../unit/img/zj.png" alt="" />
            </template>
          </myButton>
        </el-form-item>
      </el-form>
      <!-- table -->
      <div class="public-table">
        <el-table :data="tableList" :header-cell-style="{ 'text-align': 'center', background: 'rgb(245, 245, 245)' }"
          :cell-style="{ 'text-align': 'center' }">
          <el-table-column label="报修设备" prop="repair_type"></el-table-column>
          <el-table-column label="报修时间" prop="create_at"></el-table-column>
          <el-table-column label="报修人姓名" prop="merchants_name"></el-table-column>
          <el-table-column label="联系电话" prop="merchants_mobile"></el-table-column>
          <el-table-column label="问题描述" prop="repair_details" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="问题图片" prop="">
            <template #default="{ row }">
              <el-image v-if="row.repair_images.length > 0" :preview-src-list="row.repair_images" title="查看"
                :src="require('@/assets/img/icon/xianshi.png')" />
              <span v-else>暂无图片</span>
            </template>
          </el-table-column>
          <el-table-column label="处理状态" prop="repair_details">
            <template #default="{ row }">
              <span>{{ statusMap(row.repair_status) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="拒绝原因" show-overflow-tooltip prop="repair_reason">
            <template #default="{ row }">
              <span v-if="row.repair_status == 3">{{ row.repair_reason }}</span>
              <span v-else>/</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="{ row }">
              <pop tips="拒绝" :accessId="24252" v-if="row.repair_status == 0" @click="detail(row)">
                <img class="icon" src="@/assets/img/icon/tuikuan.png" alt />
              </pop>
              <pop tips="派单" :accessId="24253" v-if="row.repair_status == 0" popLeft @click="setdiapatch(row, 0)">
                <img class="icon" src="@/assets/img/icon/shujufuhe.png" alt />
              </pop>
              <pop tips="详情" :accessId="24254" popLeft @click="setdiapatch(row, 1)">
                <img class="icon" src="@/assets/img/icon/chakan.png" alt="" />
              </pop>
              <pop tips="删除" :accessId="24255" v-if="row.repair_status == 0 || row.repair_status == 3" popLeft
                @click="deleted(row)">
                <img class="icon" src="@/assets/img/icon/deleted.png" alt />
              </pop>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="public-page">
        <el-pagination :total="pageTotal" next-text="下一页" prev-text="上一页" :current-page="formInline.pageNum"
          :page-size="formInline.pageSize" @current-change="search" layout="total,prev,pager,next">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="新增报修" :visible.sync="visible" @close="handleclose" :close-on-click-modal="false">
      <el-form label-position="right" label-width="100px" ref="repairform" :rules="rules" :model="form">
        <el-form-item label="报修人" prop="merchants_name">
          <el-input v-model="form.merchants_name" class="public-input" placeholder="报修人姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="merchants_mobile">
          <el-input v-model="form.merchants_mobile" class="public-input" placeholder="报修人联系电话"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="worker_id">
          <el-select class="public-input" clearable v-model="form.worker_id" filterable remote placeholder="请输入负责人关键词"
            :remote-method="getWorkerList">
            <el-option v-for="(item, index) in workerList" :key="index" :value="item.user_id"
              :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报修设备" prop="facility_id">
          <el-select v-model="form.facility_id" @change="changeFacility" clearable filterable remote
            placeholder="请输入设备关键词" :remote-method="getDevList">
            <el-option v-for="(item, index) in devList" :key="index" :value="item.facility_id"
              :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="相关图片">
          <div class="upload-imgx-box" v-for="(item, index) in form.repair_images" :key="index">
            <img class="upload-img" :src="item" alt="" />
            <span @click="deleteImg(index)">x</span>
          </div>
          <el-upload action="" :auto-upload="false" accept="image/*" :show-file-list="false" :on-change="filechange">
            <el-button icon="el-icon-upload2" type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="地点">
          <el-input class="public-input" v-model="form.address" placeholder="">
          </el-input>
        </el-form-item>
        <el-form-item label="问题描述">
          <el-input class="public-input" v-model="form.repair_details" type="textarea" placeholder="">
          </el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <myButton @click="visible = false">取消</myButton>
        <myButton type="primary" @click="submit" left>确定</myButton>
      </template>
    </el-dialog>
    <el-dialog title="拒绝原因" :visible.sync="dealvisible" :close-on-click-modal="false">
      <el-input v-model="refuseform.repair_reason" placeholder="理由" type="textarea"></el-input>
      <template #footer>
        <myButton @click="dealvisible = false">取消</myButton>
        <myButton type="error" :disabled="!refuseform.repair_reason" @click="refuse" left>拒绝</myButton>
      </template>
    </el-dialog>
    <!-- 派单 -->

    <el-dialog :title="title" :visible.sync="dispatchvisible" class="report-dialog" :close-on-click-modal="false">
      <div class="report-title">报修信息</div>
      <el-form label-position="right" label-width="100px" :rules="reportRules" :model="dispatchform">
        <div class="report-info">
          <el-row :gutter="20">
            <el-col :span="item.span" class="item" v-for="(item, index) of reportFiledList" :key="index">
              <el-form-item :label="item.name">
                <div>{{ dispatchform[item.filed] }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="报修图片">
                <div class="img-box">
                  <el-image class="img-item" v-for="(item, index) of dispatchform.repair_images" :key="index"
                    style="width: 100px; height: 100px" :src="item" :preview-src-list="dispatchform.repair_images">
                  </el-image>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-row :gutter="20" class="mg-top-small" v-if="mode == 0">
          <el-col :span="12">
            <el-form-item label="负责人" prop="worker_id">
              <el-select class="public-input" clearable v-model="dispatchform.worker_id" filterable remote
                placeholder="请输入负责人关键词" :remote-method="getWorkerList">
                <el-option v-for="(item, index) in workerList" :key="index" :value="item.user_id"
                  :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预估费用">
              <el-input class="public-input" v-model="dispatchform.estimated_price" placeholder=""></el-input>
              <span>元</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template v-if="dispatchform.repair_status != 0">
        <div class="report-title">处理信息</div>
        <el-form label-position="right" label-width="100px">
          <div class="report-info">
            <el-row :gutter="20">
              <el-col :span="item.span" class="item" v-for="(item, index) of workerFiledList" :key="index">
                <el-form-item :label="item.name">
                  <div v-if="item.filed == 'repair_status'">
                    {{ statusMap(dispatchform[item.filed]) }}
                  </div>
                  <div v-else>
                    {{ dispatchform[item.filed] }}
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="处理后图片">
                  <div class="img-box">
                    <el-image class="img-item" v-for="(item, index) of acceptanceImagesList" :key="index"
                      style="width: 100px; height: 100px" :src="item" :preview-src-list="acceptanceImagesList">
                    </el-image>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </template>

      <template #footer v-if="mode == 0">
        <myButton @click="dispatchvisible = false">取消</myButton>
        <myButton type="primary" :disabled="!dispatchform.worker_id" @click="handledispatched" left>确认
        </myButton>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      reportFiledList: [
        { name: "商户名称", filed: "merchants_name", span: 12 },
        { name: "商铺名称", filed: "address", span: 12 },
        { name: "联系电话", filed: "merchants_mobile", span: 12 },
        { name: "报修类型", filed: "repair_type", span: 12 },
        { name: "上报时间", filed: "create_at", span: 12 },
        { name: "报修内容", filed: "repair_details", span: 24 },
      ],
      workerFiledList: [
        { name: "处理人", filed: "worker_name", span: 12 },
        { name: "处理时间", filed: "update_at", span: 12 },
        { name: "处理结果", filed: "repair_status", span: 12 },
        { name: "金额(元)", filed: "actual_price", span: 12 },
      ],
      mode: 0, //0弹窗为分配 1 为详情
      formInline: {
        pageNum: 1,
        pageSize: 10,
        repair_status: "",
      },
      title: "派单",
      form: {
        repair_details: "", //说明
        repair_images: "",
        address: "", //地址
        repair_images: [],
        repair_status: 1,
      },
      pageTotal: 0,
      tableList: [],
      visible: false,
      devList: [],
      status: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "待受理",
          value: 0,
        },
        {
          label: "派单",
          value: 1,
        },
        {
          label: "已处理",
          value: 2,
        },
        {
          label: "拒绝",
          value: 3,
        },
      ],
      desec: "",
      dealvisible: false,
      dispatchvisible: false,
      workerList: [],
      dispatchform: {
        worker_id: "",
        estimated_price: "",
        repair_status: 1,
      },
      acceptanceImagesList: [], //处理图片
      refuseform: {
        repair_reason: "",
        repair_status: 3,
        repair_id: "",
      },
      rules: {
        merchants_name: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        merchants_mobile: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        worker_id: [{ required: true, message: "请选择负责人", trigger: "change" }],
        facility_id: [{ required: true, message: "请选择保修设备", trigger: "change" }]
      },
      reportRules: {
        worker_id: [
          { required: true, message: "请选择负责人", trigger: "change" },
        ],
      },
    };
  },
  created () {
    this.getList();
    this.getDevList();
  },
  methods: {
    statusMap (status) {
      let str = "";
      switch (status) {
        case 0:
          str = "待受理";
          break;
        case 1:
          str = "派单";
          break;
        case 2:
          str = "已处理";
          break;
        case 3:
          str = "拒绝";
          break;

        default:
          break;
      }
      return str;
    },
    getList () {
      this.tableList = [];
      this.$request.HttpGet("/repair/list", this.formInline).then((res) => {
        if (res.data && res.data.list) {
          this.tableList = res.data.list;
          this.tableList.forEach((item) => {
            if (item.repair_images) {
              item.repair_images = JSON.parse(item.repair_images);
            } else {
              item.repair_images = [];
            }
          });
          this.pageTotal = res.data.total;
        }
      });
    },
    search (page = 1) {
      this.formInline.pageNum = page;
      this.getList();
    },
    getDevList (name) {
      this.$request.HttpGet("/facility/list", { name }).then((res) => {
        if (res.data) {
          this.devList = res.data.list || [];
        }
      });
    },
    filechange (file, filelist) {
      let obj = new FormData();
      obj.append("file", file.raw);
      this.$request.HttpPost("/system/upload/upImg", obj).then((res) => {
        this.form.repair_images.push(
          process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl
        );
      });
    },
    deleteImg (index) {
      this.form.images.splice(index, 1);
    },
    changeFacility (e) {
      const data = this.devList.filter(item => e === item.facility_id);
      if (data) {
        this.form.repair_type = data[0].name;
      }
    },
    submit () {
      this.$refs.repairform.validate((valid) => {
        if (valid) {
          this.$request.HttpPost("/repair/add", this.form).then((res) => {
            this.visible = false;
            this.getList();
          });
        }
      });
    },
    handleclose () {
      for (let i in this.form) {
        if (i == "repair_images") {
          this.form[i] = [];
        } else {
          this.form[i] = "";
        }
      }
    },
    detail (row) {
      this.refuseform = { ...row };
      this.refuseform.repair_status = 3;
      this.dealvisible = true;
    },
    setdiapatch (row, type) {
      this.dispatchform = { ...row, worker_id: null };
      this.getWorkerList();
      this.mode = type;
      this.title = type == 1 ? "详情" : "分配";
      this.dispatchvisible = true;
      if (!this.dispatchform?.acceptance_images?.length) {
        this.acceptanceImagesList = [];
        return;
      }
      this.acceptanceImagesList = JSON.parse(
        this.dispatchform.acceptance_images
      );
    },
    handledispatched () {
      const worker = this.workerList.find(
        (item) => item.user_id == this.dispatchform.worker_id
      );
      this.$request
        .HttpPost("/repair/edit", {
          ...this.dispatchform,
          worker_mobile: worker.mobile,
          worker_name: worker.name,
          repair_status: 1,
        })
        .then((res) => {
          this.$common.notifySuccess("操作成功");
          this.dispatchvisible = false;
          this.getList();
        });
    },
    getWorkerList (e) {
      this.$request
        .HttpGet("/worker/list", { name: e, pageSize: 999 })
        .then((res) => {
          if (res.data) {
            this.workerList = res.data.list;
          }
        });
    },
    refuse () {
      this.$request.HttpPost("/repair/edit", this.refuseform).then((res) => {
        this.dealvisible = false;
        this.$common.notifySuccess("操作成功");
        this.getList();
        console.log(res);
      });
    },
    deleted (row) {
      this.$myconfirm("确认删除该条记录").then((res) => {
        this.$request
          .HttpPost("/repair/delete", { ids: [row.repair_id] })
          .then((res) => {
            this.getList();
          });
      });
    },
  },
};
</script>
<style lang="scss">
.upload-imgx-box {
  position: relative;
  margin-right: 15px;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  display: inline-block;

  .upload-img {
    width: 100%;
    height: 100%;
  }

  span {
    position: absolute;
    right: -10px;
    top: -10px;
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid rgb(110, 108, 108);
    border-radius: 50%;
    text-align: center;
    line-height: 12px;
    cursor: pointer;
  }
}

.el-image {
  width: 16px;
}

.report-title {
  font-weight: bold;
  margin: 15px 0;
}

.report-info {
  border: 1px solid #eaeaea;
}

.img-box {
  display: flex;
  flex-wrap: wrap;

  .img-item {
    margin-right: 20px;
  }
}

.report-dialog {
  .el-dialog__body {
    padding-top: 0px !important;
  }
}

.mg-top-small {
  margin-top: 20px;
}

.el-table {
  height: 100%;
}
</style>
